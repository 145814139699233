import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { PublicClientApplication, EventType } from '@azure/msal-browser';
import { msalConfig } from './_azure/authConfig';

/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */
export const msalInstance = new PublicClientApplication(msalConfig);


// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    msalInstance.setActiveAccount(msalInstance.getActiveAccount()[0]);
}

// Listen for sign-in event and set active account + set user local storage
msalInstance.addEventCallback((event) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
        const account = event.payload.account;
        msalInstance.setActiveAccount(account);
        
    }
});

ReactDOM.render(
  <Suspense fallback="...">
    <React.StrictMode>
      <App instance={msalInstance}/>
    </React.StrictMode>
  </Suspense>,
  document.getElementById("root"),
);
