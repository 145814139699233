import { Row, Button, OverlayTrigger, Col } from "react-bootstrap";

function StageOLA({ c, olaIndex, openVTESimulationModal, openEdit, popover, className }) {
  return (
    <tr className={className}>
      <td>{c.opo.zCode}</td>
      <td>
        <Button type="button" variant="link" className="force-left" onClick={() => openVTESimulationModal(c)}>
          {c.name}
        </Button>
        <br />
        {c.assignments.map(a => a.internshipStudents).length > 0 ? c.assignments.map(a => a.internshipStudents).reduce((s, o) => s + o) : 0} /{" "}
        {c.phases.map(p => p.phase.numberOfStudents + p.surplus).reduce((s, o) => s + o)} studenten
      </td>
      <td>{c.opo.programme.name}</td>
      <td>
        <Row className="mx-2">
          {c.assignments.map((a, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <Col xs={2} className="my-2 px-1" key={i}>
              <Button variant="outline-dark" className="collegaButton" onClick={() => openEdit(olaIndex, i, a)}>
                <div className="collega-name text-truncate">
                  {localStorage.getItem("SAT-typeName") === "volledig" ? c.assignments[i].collega.firstName : c.assignments[i].collega.initials}
                </div>

                {localStorage.getItem("SAT-typeName") === "volledig" && <div className="collega-name text-truncate">{c.assignments[i].collega.lastName}</div>}
                <div>
                  {a.internshipStudents > 7 ? (
                    <>
                      <strong className="text-danger">
                        <i className="fad fa-exclamation-triangle" />
                        &nbsp;{a.internshipStudents}
                      </strong>
                    </>
                  ) : (
                    <strong>{a.internshipStudents}</strong>
                  )}
                  {a.comment !== "" && (
                    <span className="text-info">
                      &nbsp;<i className="far fa-comment-dots" />
                    </span>
                  )}
                </div>
              </Button>
            </Col>
          ))}

          <Col xs={2} className="my-2 px-1" key={c.assignments.length}>
            <OverlayTrigger trigger="click" placement="bottom" overlay={popover(c)}>
              <Button className="plusButton">
                <i className="fas fa-plus" />
              </Button>
            </OverlayTrigger>
          </Col>
        </Row>
      </td>
    </tr>
  );
}

export default StageOLA;
