import { useEffect, useState } from "react";
import { Row, Table, Container, Button, Col, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import EditModal from "./EditModal";
import ImportModal from "./ImportModal";
import RemoveModal from "./RemoveModal";
import toastService from "../../_services/toastService";
import SatAPI from "../../_services/sat";
import { getFetchParamGenerator, getApiURL } from "../../_services/sat";

const api = SatAPI();

function OPOs() {
  const snapshot = useSelector(state => state.snapshot.fileName);

  const [opos, setOPOs] = useState([]);
  const [programmes, setProgrammes] = useState([]);
  const [parallels, setParallels] = useState([]);
  const [assignments, setAssignments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modal, setModal] = useState(null);
  const [removeModal, setRemoveModal] = useState(null);

  // filter
  const [selectedProgrammeIDs, setSelectedProgrammeIDs] = useState([]);

  const loadOPOs = async (setLoad = true) => {
    try {
      setLoading(setLoad);
      const response = await api.getProgrammes(snapshot);
      response.sort((a, b) => b.name < a.name);
      setProgrammes(response);

      const classResponse = await api.getOPOs(snapshot);
      classResponse.sort((a, b) => a.programme.name.localeCompare(b.programme.name) || a.name.localeCompare(b.name));
      setOPOs(classResponse);

      // check if SAT-PersonalProgrammeFilter exists in local storage - if not: create it to avoid null reference
      if (!localStorage.getItem("SAT-PersonalProgrammeFilter")) {
        localStorage.setItem("SAT-PersonalProgrammeFilter", JSON.stringify([]));
      }

      let personalProgrammeIDs = [];
      JSON.parse(localStorage.getItem("SAT-PersonalProgrammeFilter")).forEach(selectedProgramme => {
        personalProgrammeIDs = [...personalProgrammeIDs, selectedProgramme.ID];
      });
      setSelectedProgrammeIDs(personalProgrammeIDs);

      let currentParallels = [];
      if (!snapshot) { // TEMPORARY WORKAROUND - old back-end always returns current parallels instead of parallels from snapshot
        currentParallels = await api.getParallels(snapshot);
        currentParallels.sort((a, b) => (b.mainOLA.name.localeCompare(a.mainOLA.name) || a.level - b.level) ? 1 : -1);
      }
      setParallels(currentParallels);

      const assignmentsFromAPI = await api.getAssignments(snapshot);
      setAssignments(assignmentsFromAPI);

      setLoading(false);
    } catch (error) {
      toastService.send({ title: "An error occured", message: error.toString() });
    }
  };

  const openModal = data => {
    setModal(
      <EditModal
        hideModal={() => {
          setModal(null);
          loadOPOs(false);
        }}
        data={data}
        parallels={parallels}
        assignments={assignments}
      />,
    );
  };

  const openImportModal = () => {
    setModal(
      <ImportModal
        hideModal={() => {
          setModal(null);
          loadOPOs(false);
        }}
      />,
    );
  };

  const openRemoveModal = opo => {
    setRemoveModal(
      <RemoveModal
        hideModal={() => {
          setRemoveModal(null);
          loadOPOs();
        }}
        data={opo}
      />,
    );
  };

  const exportData = async () => {
    try {
      const { fetchParamCreator, fetch } = getFetchParamGenerator();
      const opts = fetchParamCreator.getAssignmentsExport(snapshot, {});
      const resp = await fetch(getApiURL() + opts.url, opts.options);
      // download content as a file in new tab
      const file = await resp.blob();

      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    } catch (resp) {
      console.log(resp);
    }
  };

  useEffect(() => {
    loadOPOs();
  }, [snapshot]);

  const toggleSelectedProgrammeFiler = (id, checked) => {
    if (checked) {
      setSelectedProgrammeIDs([...selectedProgrammeIDs, id]);
    } else {
      setSelectedProgrammeIDs(selectedProgrammeIDs.filter(i => i !== id));
    }
  };

  if (loading) {
    return (
      <div className="text-center">
        <i className="fad fa-spinner-third fa-spin fa-5x" />
      </div>
    );
  }
  return (
    <Container>
      {modal}
      {removeModal}
      <Row>
        <h1>OPO&apos;s</h1>
      </Row>
      <Row className="my-2">
        <Col className="text-end">
          <Button variant="secondary" onClick={() => exportData()}>
            <i className="far fa-file-export" /> Exporteren
          </Button>
          &nbsp;
          {/*<Button variant="secondary" onClick={() => openImportModal()}>
            <i className="far fa-file-excel" />
            &nbsp;Importeren
          </Button>
          &nbsp;
          */}
          <Button variant="success" onClick={() => openModal()}>
            <i className="far fa-plus" />
            &nbsp;Nieuw
          </Button>
        </Col>
      </Row>
      <Row>
        <Col md={2}>
          <h3>Filter</h3>
          <hr />
          <h4>Opleiding</h4>
          <ul className="list-group">
            {programmes.map(programme => (
              <li key={programme.ID} className="list-group-item">
                <Form.Check
                  inline
                  label={programme.name}
                  id={programme.ID}
                  type="checkbox"
                  checked={selectedProgrammeIDs.includes(programme.ID)}
                  onChange={e => toggleSelectedProgrammeFiler(programme.ID, e.target.checked)}
                />
              </li>
            ))}
          </ul>
        </Col>
        <Col md={10}>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Z-Code</th>
                <th>Naam</th>
                <th>Fases</th>
                <th className="text-transparent">&nbsp;.</th>
              </tr>
            </thead>
            <tbody>
              {opos.map(c => (
                <tr key={c.ID} className={selectedProgrammeIDs.includes(c.programmeID) || selectedProgrammeIDs.length === 0 ? "" : "d-none"}>
                  <td>{c.zCode}</td>
                  <td>
                    {c.name}&nbsp;
                    {c.comments !== "" && (
                      <span className="text-info">
                        <i className="far fa-comment-dots" />
                      </span>
                    )}
                  </td>
                  <td>
                    <ul className="list-group">
                      {c.olas.map(ola =>
                        ola.phases.map(phase => (
                          <li className="list-group-item d-flex justify-content-between align-items-center">
                            {phase.phase.name} <span className="badge-primary badge-pill">{phase.phase.numberOfStudents + phase.surplus}</span>
                          </li>
                        )),
                      )}
                    </ul>
                  </td>
                  <td className="text-end">
                    <Button variant="info" onClick={() => openModal(c)}>
                      <i className="far fa-edit" />
                    </Button>{" "}
                    <Button variant="danger" onClick={() => openRemoveModal(c)} disabled={snapshot}>
                      <i className="far fa-trash" />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
}

export default OPOs;
