import { useEffect, useState } from "react";
import { Row, Table, Container, Button, Col, InputGroup, Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import SatAPI from "../../_services/sat";
import { getFetchParamGenerator, getApiURL } from "../../_services/sat";
import toastService from "../../_services/toastService";
import EditModal from "./EditModal";
import RemoveModal from "./RemoveModal";
import { getContactHours, calculateRoundedCollegaVTEPreFetched, loadParameters } from "../../_helpers/calculations";

const api = SatAPI();

function Collegas() {
  const snapshot = useSelector(state => state.snapshot.fileName);
  const [collegas, setCollegas] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editModal, setEditModal] = useState(null);
  const [removeModal, setRemoveModal] = useState(null);
  const { id } = useParams();
  const [search, setSearch] = useState("");
  const [generalParameters, setGeneralParameters] = useState(null);

  const loadCollegas = async () => {
    try {
      setLoading(true);

      console.log(snapshot);
      const response = await api.getCollegas(snapshot);
      const assignments = await api.getAssignments();
      const taskAssignments = await api.getTaskAssignments();
      const { general, cs, ects } = await loadParameters();
      setGeneralParameters(general);

      const oposResponse = await api.getOPOs(snapshot);
      oposResponse.sort((a, b) => b.name < a.name ? 1 : -1);
      let currentParallels = [];
      if (!snapshot) { // TEMPORARY WORKAROUND - old back-end always returns current parallels instead of parallels from snapshot
        currentParallels = await api.getParallels(snapshot);
        currentParallels.sort((a, b) => (b.mainOLA.name.localeCompare(a.mainOLA.name) || a.level - b.level) ? 1 : -1);
      }

      const allOLAsFromOPOS = [];
      oposResponse.forEach(opo => {
        opo.olas.forEach(ola => {
          allOLAsFromOPOS.push(ola);
        });
      });

      // TODO: improve this in new back-end!
      // Overwrite the OLA's in parallel because they don't contain OLA phases,
      // which is necessary for calculation of student numbers
      currentParallels.forEach(parallel => {
        parallel.mainOLA = allOLAsFromOPOS.find((ola) => ola.ID === parallel.mainOLAID);
        parallel.subOLA = allOLAsFromOPOS.find((ola) => ola.ID === parallel.subOLAID);
      });

      console.log("Collegas: ", response);
      console.log("Assignments: ", assignments);
      response.forEach(collega => {
        console.log("Huidige collega: ", collega);
        collega.contactHoursQ1 = getContactHours(collega, assignments, 1);
        collega.contactHoursQ2 = getContactHours(collega, assignments, 2);
        collega.contactHoursQ3 = getContactHours(collega, assignments, 3);
        collega.contactHoursQ4 = getContactHours(collega, assignments, 4);
        collega.currentVTE = calculateRoundedCollegaVTEPreFetched(collega, assignments, taskAssignments, currentParallels, assignments, general, cs, ects);
      });
      if (localStorage.getItem("SAT-typeSorting") === "firstname") {
        response.sort((a, b) => a.firstName.localeCompare(b.firstName) || a.lastName.localeCompare(b.lastName));
      } else {
        response.sort((a, b) => a.lastName.localeCompare(b.lastName) || a.firstName.localeCompare(b.firstName));
      }

      setCollegas(response);
      setLoading(false);
      return response;
    } catch (error) {
      toastService.send({ title: "An error occured", message: error });
    }
    return [];
  };

  const filter = list =>
    list.filter(c => {
      if (search === "") {
        return true;
      }
      return `${c.firstName} ${c.lastName} ${c.firstName} ${c.initials} ${c.nameOrangeGuide}`.toLowerCase().includes(search.toLowerCase()); // clever trick to search on first and last name
    });

  const openModal = data => {
    setEditModal(
      <EditModal
        hideModal={() => {
          setEditModal(null);
          loadCollegas();
        }}
        data={data}
      />,
    );
  };

  const openRemoveModal = collega => {
    setRemoveModal(
      <RemoveModal
        hideModal={() => {
          setRemoveModal(null);
          loadCollegas();
        }}
        data={collega}
      />,
    );
  };

  const exportData = async () => {
    try {
      const { fetchParamCreator, fetch } = getFetchParamGenerator();
      const opts = fetchParamCreator.getCollegasExport(snapshot, {});
      const resp = await fetch(getApiURL() + opts.url, opts.options);
      // download content as a file in new tab
      const file = await resp.blob();

      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    } catch (resp) {
      console.log(resp);
    }
  };

  useEffect(() => {
    loadCollegas().then(resp => {
      if (id) {
        console.log("ID: ", id, collegas);
        const collega = resp.find(c => parseInt(c.ID, 10) === parseInt(id, 10));
        console.log("Collega: ", collega);
        if (collega) {
          openModal(collega);
        }
      }
    });
  }, [snapshot]);

  if (loading) {
    return (
      <div className="text-center">
        <i className="fad fa-spinner-third fa-spin fa-5x" />
      </div>
    );
  }
  return (
    <Container>
      {editModal}
      {removeModal}
      <Row>
        <h1>Docenten</h1>
      </Row>
      <Row className="my-2">
        <Col className="text-begin">
          <InputGroup className="mb-3">
            <InputGroup.Text id="basic-addon1">
              <i className="fas fa-search" />
            </InputGroup.Text>
            <Form.Control type="text" required onChange={e => setSearch(e.target.value, 10)} value={search} />
          </InputGroup>
        </Col>
        <Col className="text-end">
          <Button variant="secondary" onClick={() => exportData()}>
            <i className="far fa-file-export" /> Exporteren
          </Button>{" "}
          <Button variant="success" onClick={() => openModal()} disabled={snapshot}>
            <i className="far fa-plus" />
            &nbsp;Nieuw
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Voornaam</th>
                <th>Achternaam</th>
                <th>Verlofstelsel</th>
                <th>% in unit</th>
                <th>
                  <Table striped className="table-contactHours">
                    <thead>
                      <tr>
                        <th>Q1</th>
                        <th>Q2</th>
                        <th>Q3</th>
                        <th>Q4</th>
                      </tr>
                    </thead>
                  </Table>
                </th>
                <th>VTE %</th>
                <th>Kleur</th>
                <th className="text-transparent">&nbsp;.</th>
              </tr>
            </thead>
            <tbody>
              {filter(collegas).map(collega => (
                <tr key={collega.id}>
                  <td>
                    {collega.comments !== "" && (
                      <span className="text-info">
                        <i className="far fa-comment-dots" />&nbsp;
                      </span>
                    )}
                    {collega.firstName}
                  </td>
                  <td>{collega.lastName}</td>
                  <td>{collega.leaveSchedule}</td>
                  <td>{collega.percentageInUnit}</td>
                  <td>
                    <Table striped className="table-contactHours">
                      <tbody>
                        <tr>
                          <td className="contactHours">
                            {collega.contactHoursQ1 > generalParameters.contactHoursLimit ? (
                              <strong className="text-danger">{collega.contactHoursQ1}</strong>
                            ) : (
                              collega.contactHoursQ1
                            )}
                          </td>
                          <td className="contactHours">
                            {collega.contactHoursQ2 > generalParameters.contactHoursLimit ? (
                              <strong className="text-danger">{collega.contactHoursQ2}</strong>
                            ) : (
                              collega.contactHoursQ2
                            )}
                          </td>
                          <td className="contactHours">
                            {collega.contactHoursQ3 > generalParameters.contactHoursLimit ? (
                              <strong className="text-danger">{collega.contactHoursQ3}</strong>
                            ) : (
                              collega.contactHoursQ3
                            )}
                          </td>
                          <td className="contactHours">
                            {collega.contactHoursQ4 > generalParameters.contactHoursLimit ? (
                              <strong className="text-danger">{collega.contactHoursQ4}</strong>
                            ) : (
                              collega.contactHoursQ4
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </td>
                  <td><span className={(collega.currentVTE < (collega.percentageInUnit - collega.leaveSchedule) - 2.5 || collega.currentVTE > (collega.percentageInUnit - collega.leaveSchedule) + 2.5) && "font-bold text-danger"}>{collega.currentVTE}</span> {(collega.fundingSource !== "" && collega.fundingSource !== null) && (
                    <span className="text-danger">
                      <i className="fas fa-flag" />&nbsp;
                    </span>
                  )}</td>
                  <td style={{ backgroundColor: collega.color }} className="text-transparent">.</td>
                  <td className="text-end">
                    <Button variant="info" onClick={() => openModal(collega)}>
                      <i className="far fa-edit" />
                    </Button>{" "}
                    <Button variant="danger" onClick={() => openRemoveModal(collega)} disabled={snapshot}>
                      <i className="far fa-trash" />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
}

export default Collegas;
