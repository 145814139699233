import { Row } from "react-bootstrap";
import Tetris from "react-tetris";


function CoffeeBreak() {
  return (
    <>
          <h1 className="display-5 fw-bold">Tijd voor een pauze</h1>
          <Tetris
      keyboardControls={{
        // Default values shown here. These will be used if no
        // `keyboardControls` prop is provided.
        down: 'MOVE_DOWN',
        left: 'MOVE_LEFT',
        right: 'MOVE_RIGHT',
        space: 'HARD_DROP',
        z: 'FLIP_COUNTERCLOCKWISE',
        x: 'FLIP_CLOCKWISE',
        up: 'FLIP_CLOCKWISE',
        p: 'TOGGLE_PAUSE',
        c: 'HOLD',
        shift: 'HOLD'
      }}
    >
      {({
        HeldPiece,
        Gameboard,
        PieceQueue,
        points,
        linesCleared,
        state,
        controller
      }) => (
        <>
        
        <div style={{display: "flex", gap: "5em", justifyContent: "center", alignItems:"start"}}>
          <div style={{flex: ""}}>
          <HeldPiece />
          <div>
            <p>Points: {points}</p>
            <p>Lines Cleared: {linesCleared}</p>
          </div>
          {state === 'LOST' && (
            <div>
                <div>
              <p>Game Over</p>
              <button onClick={controller.restart}>New game</button>
              </div>
            </div>
          )}
          </div>
          
          <Gameboard />
          <PieceQueue />
          
        </div>
        
        </>
      )}
    </Tetris>
    </>
  );
}

export default CoffeeBreak;