import { useEffect, useState } from "react";
import { Row, Container, Button, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import ReactDataGrid from '@inovua/reactdatagrid-community'
import NumberFilter from '@inovua/reactdatagrid-community/NumberFilter'
import StringFilter from '@inovua/reactdatagrid-community/StringFilter'
import '@inovua/reactdatagrid-community/index.css'
import EditModal from "./EditModal";
import RemoveModal from "./RemoveModal";
import toastService from "../../_services/toastService";
import SatAPI from "../../_services/sat";
import { downloadBlob } from "../../_helpers/excel";

const api = SatAPI();

function Phases() {
  const snapshot = useSelector(state => state.snapshot.fileName);

  const [phases, setPhases] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editModal, setEditModal] = useState(null);
  const [removeModal, setRemoveModal] = useState(null);
  const [gridRef, setGridRef] = useState(null);

  const loadPhases = async () => {
    try {
      setLoading(true);

      // check if SAT-PersonalProgrammeFilter exists in local storage - if not: create it to avoid null reference
      if (!localStorage.getItem('SAT-PersonalProgrammeFilter')) {
        localStorage.setItem('SAT-PersonalProgrammeFilter', JSON.stringify([]));
      }

      let personalProgrammeIDs = [];
      JSON.parse(localStorage.getItem('SAT-PersonalProgrammeFilter')).forEach(selectedProgramme => {
        personalProgrammeIDs = [...personalProgrammeIDs, selectedProgramme.ID];
      });

      let classResponse = await api.getPhases(0, snapshot);
      const opos = await api.getOPOs(snapshot);
      classResponse.sort((a, b) => a.programme.name.localeCompare(b.programme.name) || a.name.localeCompare(b.name));

      if (personalProgrammeIDs.length > 0) {
        classResponse.forEach(phase => {
          if (personalProgrammeIDs.indexOf(phase.programmeID) === -1) {
            classResponse = classResponse.filter((ph) => ph.ID !== phase.ID);
          }
        });
      }

      // Calculate the number of ECTS credits per phase
      classResponse.forEach(phase => {
        let phaseCredits = 0;
        opos.forEach(opo => {
          opo.olas.forEach(ola => {
            ola.phases.forEach(olaPhase => {
              if (olaPhase.phaseID === phase.ID && ola.realECTSCredits !== null) {
                phaseCredits += ola.realECTSCredits;
              }
            });
          });
        });
        phase.phaseCredits = phaseCredits;
      });

      setPhases(classResponse);

      setLoading(false);
    } catch (error) {
      toastService.send({ title: "An error occured", message: error.toString() });
    }
  };

  const openEditModal = programme => {
    setEditModal(
      <EditModal
        hideModal={() => {
          setEditModal(null);
          loadPhases();
        }}
        data={programme}
      />,
    );
  };

  const openRemoveModal = programme => {
    setRemoveModal(
      <RemoveModal
        hideModal={() => {
          setRemoveModal(null);
          loadPhases();
        }}
        data={programme}
      />,
    );
  };

  const gridStyle = { minHeight: 550 }

  const initialColumns = [
    { name: 'name', header: 'Naam', defaultFlex: 1, filterEditor: StringFilter },
    { name: 'numberOfStudents', header: 'Aantal studenten', type: 'number', defaultFlex: 1, filterEditor: NumberFilter },
    { name: 'numberOfClasses', header: 'Aantal klassen', type: 'number', defaultFlex: 1, filterEditor: NumberFilter },
    {
      name: 'programme', header: 'Opleiding', defaultFlex: 1, sortable: true,
      sort: (p1, p2) =>
        p1.name.localeCompare(p2.name) ? 1 : -1, render: ({ data }) => <span>{data.programme.name}</span>,
      filterEditor: StringFilter
    },
    { name: 'phaseCredits', header: 'Studiepunten ECTS', type: 'number', defaultFlex: 1, filterEditor: NumberFilter },
    {
      name: 'color', header: 'Kleur', defaultFlex: 1, onRender: (cellProps, { data }) => {
        cellProps.style.background = data.color
      },
      style: { color: 'transparent' }
    },
    {
      name: 'ID', header: 'Acties', defaultFlex: 1, draggable: false, sortable: false, render: ({ data }) => <div>
        <Button variant="info" onClick={() => openEditModal(data)} disabled={snapshot}>
          <i className="far fa-edit" />
        </Button>
        <Button variant="danger" onClick={() => openRemoveModal(data)} disabled={snapshot}>
          <i className="far fa-trash" />
        </Button></div>
    }
  ];

  const [columns] = useState(initialColumns);

  const filterValue = [
    { name: 'name', operator: 'contains', type: 'string', value: '' },
    { name: 'numberOfStudents', operator: 'inrange', type: 'number', value: 0 },
    { name: 'numberOfClasses', operator: 'inrange', type: 'number', value: 0 },
    { name: 'phaseCredits', operator: 'inrange', type: 'number', value: 0 },
  ];

  const exportCSV = () => {
    const { visibleColumns } = gridRef.current;

    const header = visibleColumns.map((c) => c.name).join(',');
    const rows = gridRef.current.data.map((data) => visibleColumns.map((c) => data[c.id]).join(','));

    const contents = [header].concat(rows).join('\n');
    const blob = new Blob([contents], { type: 'text/csv;charset=utf-8;' });

    downloadBlob(blob, 'fasen.csv');
  };

  useEffect(() => {
    loadPhases();
  }, [snapshot]);

  if (loading) {
    return (
      <div className="text-center">
        <i className="fad fa-spinner-third fa-spin fa-5x" />
      </div>
    );
  }
  return (
    <Container>
      {editModal}
      {removeModal}
      <Row>
        <h1>Fasen</h1>
      </Row>
      <Row className="my-2">
        <Col className="text-end">
          <Button variant="secondary" onClick={() => exportCSV()}>
            <i className="far fa-file-export" /> Exporteren
          </Button>{" "}
          <Button variant="success" onClick={() => openEditModal()} disabled={snapshot}>
            <i className="far fa-plus" />
            &nbsp;Nieuw
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <ReactDataGrid
            handle={setGridRef}
            idProperty="ID"
            style={gridStyle}
            defaultFilterValue={filterValue}
            columns={columns}
            dataSource={phases}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default Phases;
