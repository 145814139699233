let api = "https://localhost:4000"
if (window.location.href.indexOf("sat.thomasmore.be") > -1) {
    
    api = "https://app-prod-backend.azurewebsites.net";
}
if(window.location.href.indexOf("sat-sandbox.thomasmore.be") > -1){
    api = "https://app-sandbox-backend.azurewebsites.net"
}

console.log = () => { };
export const LOGIN_URL = process.env.REACT_APP_API_URL || "https://api.sat.maartje.dev";
export const API_URL = api;
