import { useEffect, useState } from "react";
import { Row, Table, Container, Col, Form, Button, OverlayTrigger, Popover } from "react-bootstrap";
import { useSelector } from "react-redux";
import SatAPI from "../../../_services/sat";
import toastService from "../../../_services/toastService";
import DocentList from "../olas/components/DocentList";
import AssignmentEdit from "./components/AssignmentEdit";
import { exportToExcel } from "../../../_helpers/excel";

const api = SatAPI();

function TaskAssignment() {
  const snapshot = useSelector(state => state.snapshot.fileName);

  const [tasks, setTasks] = useState([]);
  const [programmes, setProgrammes] = useState([]);
  const [loading, setLoading] = useState(true);

  const [modal, setModal] = useState(null);
  const [hasChanges, setHasChanges] = useState({});
  const [saving, setSaving] = useState(false);
  const [deletedTaskAssignments, setDeletedTaskAssignments] = useState([]);

  // filters
  const [selectedProgrammeIDs, setSelectedProgrammeIDs] = useState([]);

  const markAsChanged = id => {
    setHasChanges({ ...hasChanges, [id]: true });
  };

  const calculateVTE = (task, programme) => {
    let vtePercentage = 0;
    task.assignments
      .filter(ta => ta.programmeID === programme.ID)
      .forEach(a => {
        if (a.percentageOverride > 0) {
          vtePercentage += a.percentageOverride;
          return;
        }
        vtePercentage += task.percentage;
      });

    return vtePercentage / 100;
  };

  const load = async (setLoad = true) => {
    try {
      setLoading(setLoad);
      const response = await api.getProgrammes(snapshot);
      response.sort((a, b) => b.name < a.name);
      setProgrammes(response);

      const assignments = await api.getTaskAssignments(snapshot);
      const tasksResponse = await api.getTasks(snapshot);
      tasksResponse.sort((a, b) => a.name.localeCompare(b.name));

      tasksResponse.forEach(task => {
        task.assignments = assignments.filter(a => a.taskID === task.ID);
        task.assignments.sort((a, b) => a.collega.lastName > b.collega.lastName);
      });

      setTasks(tasksResponse);

      // check if SAT-PersonalProgrammeFilter exists in local storage - if not: create it to avoid null reference
      if (!localStorage.getItem('SAT-PersonalProgrammeFilter')) {
        localStorage.setItem('SAT-PersonalProgrammeFilter', JSON.stringify([]));
      }

      let personalProgrammeIDs = [];
      JSON.parse(localStorage.getItem('SAT-PersonalProgrammeFilter')).forEach(selectedProgramme => {
        personalProgrammeIDs = [...personalProgrammeIDs, selectedProgramme.ID];
      });
      setSelectedProgrammeIDs(personalProgrammeIDs);

      setLoading(false);
    } catch (error) {
      toastService.send({ title: "An error occured", message: error.toString() });
    }
  };

  useEffect(() => {
    load();
  }, [snapshot]);

  const removeAssignment = (taskIndex, assignmentIndex) => {
    if (tasks[taskIndex].assignments[assignmentIndex].ID) {
      setDeletedTaskAssignments([...deletedTaskAssignments, tasks[taskIndex].assignments[assignmentIndex]]);
    }

    const newTasks = [...tasks];
    newTasks[taskIndex].assignments.splice(assignmentIndex, 1);
    setTasks(newTasks);
    markAsChanged(newTasks[taskIndex].ID);
  };

  const updateAssignment = (taskIndex, assignmentIndex, data) => {
    const newTasks = [...tasks];
    newTasks[taskIndex].assignments[assignmentIndex] = data;
    setTasks(newTasks);
    markAsChanged(newTasks[taskIndex].ID);
  };

  const openEdit = (taskIndex, assignmentIndex, data) => {
    console.log(data);
    setModal(
      <AssignmentEdit
        hideModal={() => {
          setModal(null);
        }}
        data={data}
        saveData={d => updateAssignment(taskIndex, assignmentIndex, d)}
        removeAssignment={() => removeAssignment(taskIndex, assignmentIndex)}
      />,
    );
  };

  const toggleSelectedProgrammeFilter = (id, checked) => {
    if (checked) {
      setSelectedProgrammeIDs([...selectedProgrammeIDs, id]);
    } else {
      setSelectedProgrammeIDs(selectedProgrammeIDs.filter(i => i !== id));
    }
  };

  const save = async () => {
    setSaving(true);
    try {
      await Promise.all(
        tasks
          .filter(task => hasChanges[task.ID])
          .map(task =>
            task.assignments.map(async assignment => {
              assignment.taskID = task.ID;
              if (assignment.ID) {
                await api.updateTaskAssignment(assignment.ID, assignment);
              } else {
                await api.createTaskAssignment(assignment);
              }
            }),
          ),
      );
      setHasChanges({});
      await Promise.all(
        deletedTaskAssignments.map(async assignment => {
          await api.deleteTaskAssignment(assignment.ID);
        }),
      );
      setDeletedTaskAssignments([]);

      await load(false);

      api.createSnapshot();
      toastService.send({ title: "Saved", message: "Saved successfully" });
    } catch (error) {
      toastService.send({ title: "An error occured", message: error.toString() });
    }

    setSaving(false);
  };

  const saveExcel = async () => {

    const exportData = [];
    tasks.forEach((task) => {
      task.programmes.forEach((programme) => {
        if (selectedProgrammeIDs.length === 0 || selectedProgrammeIDs.includes(programme.ID)) {
          task.assignments.forEach((assignment) => {
            if (assignment.programmeID === programme.ID) {
              exportData.push({
                'Taak': task.name,
                'Opleiding': programme.name,
                'Voornaam': assignment.collega.firstName,
                'Achternaam': assignment.collega.lastName,
                'Percentage': assignment.percentageOverride || task.percentage,
                'Onderwijsactiviteit': task.educationalActivity ? "Ja" : "Nee",
                'Opmerking': assignment.comment
              });
            }
          });
        }
      });
    });
    exportToExcel(exportData, 'Taaktoewijzingen');
  }

  if (loading) {
    return (
      <div className="text-center">
        <i className="fad fa-spinner-third fa-spin fa-5x" />
      </div>
    );
  }

  const popover = (o, programme) => (
    <Popover className="docent-list">
      <Popover.Header as="h3">Docent toekennen</Popover.Header>
      <Popover.Body>
        <DocentList
          programme={programme}
          selectDocent={collega => {
            setTasks(
              tasks.map(task => {
                if (task.ID === o.ID) {
                  task.assignments.push({
                    collega,
                    collegaID: collega.ID,
                    programme,
                    programmeID: programme.ID,
                    comment: "",
                  });
                }
                return task;
              }),
            );
            markAsChanged(o.ID);
          }}
          ola={null}
        />
      </Popover.Body>
    </Popover>
  );

  return (
    <Container>
      {modal}
      <Row>
        <Col>
          <h1>Taken</h1>
        </Col>
        <Col className="d-flex justify-content-end align-items-start">
          <Button variant="secondary" onClick={saveExcel} disabled={saving || snapshot} className="fixedElement" style={{ marginInlineEnd: "8rem" }}>
            <i className="far fa-file-export" />
            &nbsp;Exporteren
          </Button>
          <Button variant="success" onClick={save} disabled={saving || snapshot} className="fixedElement">
            <i className="far fa-save" />
            &nbsp;Opslaan
          </Button>
        </Col>
      </Row>

      <Row>
        <Col md={2}>
          <h3>Filter</h3>
          <hr />
          <h4>Opleiding</h4>
          <ul className="list-group">
            {programmes.map(programme => (
              <li key={programme.ID} className="list-group-item">
                <Form.Check
                  inline
                  label={programme.name}
                  id={programme.ID}
                  type="checkbox"
                  checked={selectedProgrammeIDs.includes(programme.ID)}
                  onChange={e => toggleSelectedProgrammeFilter(programme.ID, e.target.checked)}
                />
              </li>
            ))}
          </ul>
        </Col>
        <Col md={10}>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Naam</th>
                <th>Opleiding</th>
                <th className="assigncol">Invulling</th>
              </tr>
            </thead>
            <tbody>
              {tasks.map((task, taskIndex) =>
                task.programmes.map(programme => (
                  <tr key={task.ID} className={selectedProgrammeIDs.includes(programme.ID) || selectedProgrammeIDs.length === 0 ? "" : "d-none"}>
                    <td>
                      {task.name}
                      <br />
                      <i className="fas fa-arrow-right" />
                      &nbsp;{calculateVTE(task, programme).toFixed(4)}&nbsp;VTE
                    </td>
                    <td>{programme.name}</td>
                    <td>
                      <Row className="mx-2">
                        {task.assignments.map(
                          (ta, i) =>
                            ta.programmeID === programme.ID && (
                              // eslint-disable-next-line react/no-array-index-key
                              <Col xs={2} className="my-2 px-1" key={i}>
                                <Button variant="outline-dark" className="collegaButton" onClick={() => openEdit(taskIndex, i, ta)}>
                                  <div className="collega-name text-truncate">
                                    {localStorage.getItem("SAT-typeName") === "volledig"
                                      ? ta.collega.firstName
                                      : ta.collega.initials}
                                  </div>

                                  {localStorage.getItem("SAT-typeName") === "volledig" && (
                                    <div className="collega-name text-truncate">{ta.collega.lastName}</div>
                                  )}
                                  <div className="collega-name text-truncate fw-bold">
                                    {ta.percentageOverride || task.percentage}%{" "}
                                    {ta.comment !== "" && (
                                      <span className="text-info">
                                        <i className="far fa-comment-dots" />
                                      </span>
                                    )}
                                  </div>
                                </Button>
                              </Col>
                            ),
                        )}
                        <Col xs={2} className="my-2 px-1" key={task.assignments.length}>
                          <OverlayTrigger trigger="click" placement="bottom" overlay={popover(task, programme)}>
                            <Button className="plusButton">
                              <i className="fas fa-plus" />
                            </Button>
                          </OverlayTrigger>
                        </Col>
                      </Row>
                    </td>
                  </tr>
                )),
              )}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container >
  );
}

export default TaskAssignment;
