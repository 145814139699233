import { useEffect, useState } from "react";
import { Row, Table, Container, Button, Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import EditModalECTS from "./EditModalECTS";
import EditModalClassSize from "./EditModalClassSize";
import RemoveModalECTS from "./RemoveModalECTS";
import RemoveModalClassSize from "./RemoveModalClassSize";
import toastService from "../../_services/toastService";
import SatAPI from "../../_services/sat";
import EditModalGlobalSettings from "./EditModalGlobalSettings";

const api = SatAPI();

function Parameters() {
  const snapshot = useSelector(state => state.snapshot.fileName);

  const [generalParameters, setGeneralParameters] = useState({});
  const [classSizes, setClassSizes] = useState([]);
  const [ects, setECTS] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editModal, setEditModal] = useState(null);
  const [removeModal, setRemoveModal] = useState(null);

  const loadParams = async () => {
    try {
      setLoading(true);

      setGeneralParameters(await api.getGlobalSettings(snapshot));
      const cs = await api.getClassSizesParameters(snapshot);
      cs.sort((a, b) => a.min - b.min);
      setClassSizes(cs);
      const ectsResp = await api.getECTSLoadsParameters(snapshot);
      ectsResp.sort((a, b) => a.min - b.min);
      setECTS(ectsResp);

      setLoading(false);
    } catch (error) {
      if (typeof error.json === "function") {
        error.json().then(jsonError => {
          toastService.send({ title: "Error", message: jsonError.message });
          console.log(jsonError);
        });
      } else {
        toastService.send({ title: "Error", message: "Error bij verbinden naar server" });
      }
    }
  };
  const openModalClassSize = data => {
    setEditModal(
      <EditModalClassSize
        hideModal={() => {
          setEditModal(null);
          loadParams();
        }}
        data={data}
      />,
    );
  };

  const openModalGlobalSettings = data => {
    setEditModal(
      <EditModalGlobalSettings
        hideModal={() => {
          setEditModal(null);
          loadParams();
        }}
        data={data}
      />,
    );
  };

  const openModalECTS = data => {
    setEditModal(
      <EditModalECTS
        hideModal={() => {
          setEditModal(null);
          loadParams();
        }}
        data={data}
      />,
    );
  };

  const openRemoveModalClassSize = parameters => {
    setRemoveModal(
      <RemoveModalClassSize
        hideModal={() => {
          setRemoveModal(null);
          loadParams();
        }}
        data={parameters}
      />,
    );
  };

  const openRemoveModalECTS = parameters => {
    setRemoveModal(
      <RemoveModalECTS
        hideModal={() => {
          setRemoveModal(null);
          loadParams();
        }}
        data={parameters}
      />,
    );
  };

  useEffect(() => {
    loadParams();
  }, [snapshot]);

  if (loading) {
    return (
      <div className="text-center">
        <i className="fad fa-spinner-third fa-spin fa-5x" />
      </div>
    );
  }
  return (
    <Container>
      {editModal}
      {removeModal}
      <Row>
        <h1>Algemeen</h1>
      </Row>
      <Row className="my-2">
        <Col className="text-end">
          <Button variant="success" onClick={() => openModalGlobalSettings(generalParameters)} disabled={snapshot}>
            <i className="far fa-pencil" />
            &nbsp;Bewerk
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <ul>
            <li>
              Weergegeven academiejaar: <strong>{generalParameters.academicYear}</strong>
            </li>
            <li>
              Uur fulltime: <strong>{generalParameters.hoursFullTime} uur/100</strong>
            </li>
            <li>
              Extra OPO-coördinator: <strong>{generalParameters.opoCoordinatorExtra} %</strong>
            </li>
            <li>
              Limiet contacturen (per kwartaal): <strong>{generalParameters.limitContactHours} uur</strong>
            </li>
            <li>
              Toegestane afwijking percentage per docent op opleidingsniveau: <strong>{generalParameters.allowedDeviation} %</strong>
            </li>
          </ul>
        </Col>
      </Row>
      <Row>
        <h1>Klasgrootte</h1>
      </Row>
      <Row className="my-2">
        <Col className="text-end">
          <Button variant="success" onClick={() => openModalClassSize()} disabled={snapshot}>
            <i className="far fa-plus" />
            &nbsp;Nieuw
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Naam</th>
                <th>Korte naam</th>
                <th>Min</th>
                <th>Max</th>
                <th>Eerste</th>
                <th>Parallel</th>
                <th className="text-transparent">&nbsp;.</th>
              </tr>
            </thead>
            <tbody>
              {classSizes.map(cs => (
                <tr key={cs.id}>
                  <td>{cs.name}</td>
                  <td>{cs.shortName}</td>
                  <td>{cs.min}</td>
                  <td>{cs.max}</td>
                  <td>{cs.firstLoad}</td>
                  <td>{cs.parallelLoad}</td>
                  <td className="text-end">
                    <Button variant="info" onClick={() => openModalClassSize(cs)}>
                      <i className="far fa-edit" />
                    </Button>{" "}
                    <Button variant="danger" onClick={() => openRemoveModalClassSize(cs)} disabled={snapshot}>
                      <i className="far fa-trash" />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
      <Row>
        <h1>Studiepunten</h1>
      </Row>
      <Row className="my-2">
        <Col className="text-end">
          <Button variant="success" onClick={() => openModalECTS()} disabled={snapshot}>
            <i className="far fa-plus" />
            &nbsp;Nieuw
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Min</th>
                <th>Max</th>
                <th>Belasting</th>
                <th className="text-transparent">&nbsp;.</th>
              </tr>
            </thead>
            <tbody>
              {ects.map(e => (
                <tr key={e.id}>
                  <td>{e.min}</td>
                  <td>{e.max}</td>
                  <td>{e.hoursPerGrade} uur</td>
                  <td className="text-end">
                    <Button variant="info" onClick={() => openModalECTS(e)}>
                      <i className="far fa-edit" />
                    </Button>{" "}
                    <Button variant="danger" onClick={() => openRemoveModalECTS(e)} disabled={snapshot}>
                      <i className="far fa-trash" />
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
}

export default Parameters;
