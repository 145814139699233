import { useState } from "react";
import { useSelector } from "react-redux";
import { Modal, Row, Form, Col, Button } from "react-bootstrap";
import SatAPI from "../../_services/sat";
import toastService from "../../_services/toastService";

const api = SatAPI();

function EditModalGlobalSettings({ data = {}, hideModal }) {
  const snapshot = useSelector(state => state.snapshot.fileName);

  const [academicYear, setAcademicYear] = useState(data.academicYear || "not found");
  const [hoursFullTime, setHoursFullTime] = useState(data.hoursFullTime || 0);
  const [opoCoordinatorExtra, setOpoCoordinatorExtra] = useState(data.opoCoordinatorExtra || 0);
  const [limitContactHours, setLimitContactHours] = useState(data.limitContactHours || 0);
  const [allowedDeviation, setAllowedDeviation] = useState(data.allowedDeviation || 0);

  const onFormSubmit = async e => {
    e.preventDefault();
    try {
        await api.updateGlobalSettings({
          academicYear,
          hoursFullTime,
          opoCoordinatorExtra,
          limitContactHours,
          allowedDeviation,
        });

      api.createSnapshot();
      toastService.send({ title: "Success", message: "Globale instellingen zijn opgeslagen" });
      hideModal();
    } catch (error) {
      toastService.send({ title: "An error occured", message: error });
    }
  };
  return (
    <Modal
      show
      onHide={() => {
        hideModal();
      }}
      backdrop="static"
      keyboard={false}
      centered
      size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Globale instellingen bewerken</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xs={12}>
            <Form onSubmit={onFormSubmit}>
              <Form.Group className="mb-3">
                <Form.Label>Weergegeven academiejaar</Form.Label>
                <Form.Control type="text" required onChange={e => setAcademicYear(e.target.value)} value={academicYear} />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Uur fulltime</Form.Label>
                <Form.Control type="number" step="0.01" required onChange={e => setHoursFullTime(parseFloat(e.target.value, 10))} value={hoursFullTime} min={0} />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Extra OPO-coördinator</Form.Label>
                <Form.Control type="number" step="0.01" required onChange={e => setOpoCoordinatorExtra(parseInt(e.target.value, 10))} value={opoCoordinatorExtra} />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Limiet contacturen</Form.Label>
                <Form.Control type="number" step="0.01" required onChange={e => setLimitContactHours(parseFloat(e.target.value, 10))} value={limitContactHours} min={0} />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Toegestane afwijking percentage per docent op opleidingsniveau</Form.Label>
                <Form.Control type="number" step="0.01" required onChange={e => setAllowedDeviation(parseFloat(e.target.value, 10))} value={allowedDeviation} min={0} />
              </Form.Group>

              <Button variant="primary" type="submit" disabled={snapshot}>
                 <span>
                    <i className="far fa-edit" />&nbsp;Bewerk
                  </span>
              </Button>
            </Form>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default EditModalGlobalSettings;
