import { Row } from "react-bootstrap";


function Home() {

  const random = Math.round(Math.random() * 100);
  const hint = random == 44;

  return (
    <>
      <Row>
        <div className="container-fluid py-5">
          <h1 className="display-5 fw-bold">Thomas More Staff Assignment Tool</h1>
          {/* <img className="col-md-8 fs-4 home-image" src="\SATSummer.png" alt="Planning under construction" /> */}
          {hint &&
          <>
          <blockquote className="blockquote2"><p>Sadly, unlimited lives only exist in video games</p></blockquote>
          <p className="float-end">~Arda Öcal, on the death of Kazuhisa Hashimoto</p>
          </>
          }
          {!hint &&
          <>
          <blockquote className="blockquote"><p>If Plan A doesn&apos;t work, the alphabet has 25 more letters — 204 if you&apos;re in Japan.</p></blockquote>
          <p className="float-end">~Claire Cook</p>
          </>
          }
          
        </div>
      </Row>
    </>
  );
}

export default Home;
