import { useEffect, useState } from "react";
import { Row, Form, Col, Button } from "react-bootstrap";
import SatAPI from "../../_services/sat";

const api = SatAPI();

function EditProgramme({ data = {}, removeProgramme, saveData }) {
  const [programmeID, setProgrammeID] = useState(data.programmeID || 0);
  const [percentage, setPercentage] = useState(data.percentage || 100);
  const [allProgrammes, setAllProgrammes] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const pResponse = await api.getProgrammes();
      pResponse.sort((a, b) => b.name < a.name);
      setAllProgrammes(pResponse);
    };
    fetchData();
  }, []);

  useEffect(() => {
    data.programmeID = programmeID;
    data.percentage = percentage;
    saveData(data);
  }, [programmeID, percentage]);

  return (
    <Row className="border m-3 p-2">
      <Form>
        <Row className="mb-3">
          <Col xs={5}>
            <Form.Group className="mb-3">
              <Form.Label>Opleiding</Form.Label>
              <Form.Select onChange={e => setProgrammeID(parseInt(e.target.value, 10))} value={programmeID}>
                <option value={null}>Selecteer een opleiding</option>
                {allProgrammes.map(p => (
                  <option key={p.ID} value={p.ID} selected={p.ID === programmeID}>
                    {p.name}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Col>
          <Col xs={5}>
            <Form.Group className="mb-3">
              <Form.Label>Percentage</Form.Label>
              <Form.Control type="number" min="0" max="100" required onChange={e => setPercentage(parseFloat(e.target.value, 10))} value={percentage} />
            </Form.Group>
          </Col>
          <Col xs={1}>
            <Form.Label>&nbsp;</Form.Label>
            <Button variant="danger" onClick={() => removeProgramme()}>
              <i className="far fa-trash" />
            </Button>
          </Col>
        </Row>
      </Form>
    </Row>
  );
}

export default EditProgramme;
