import { useEffect, useState } from "react";
import { Modal, Row, Form, Col, Button } from "react-bootstrap";
import { WithContext as ReactTags } from "react-tag-input";
import { useSelector } from "react-redux";
import toastService from "../../_services/toastService";
import EditOLA from "./EditOLA";
import MessageModal from "../_components/MessageModal";
import { calculateOPOVTEWorstCase, calculateOPOVTEBestCase } from "../../_helpers/calculations";
import SatAPI from "../../_services/sat";

const KeyCodes = {
  comma: 188,
  enter: 13,
};

const api = SatAPI();

function EditModal({ data = {}, hideModal, parallels, assignments }) {
  const [saving, setSaving] = useState(false);

  const snapshot = useSelector(state => state.snapshot.fileName);

  const [name, setName] = useState(data.name || "");
  const [zCode, setZCode] = useState(data.zCode || "");
  const [programmeID, setProgramme] = useState(data.programmeID || 0);
  const [programmes, setProgrammes] = useState([]);
  const [tags, setTags] = useState(data.tags || []);
  const [olas, setOLAs] = useState(data.olas || []);
  const [worstCaseVTE, setVTEWorstCase] = useState(0);
  const [bestCaseVTE, setVTEBestCase] = useState(0);
  const [comments, setComments] = useState(data.comments || "");
  const [hasInvalidOLA, setHasInvalidOLA] = useState(false);

  const [messageModal, setMessageModal] = useState(null);

  const openMessageModal = (title, message) => {
    setMessageModal(
      <MessageModal
        hideModal={() => {
          setMessageModal(null);
        }}
        title={title}
        message={message}
        icon="fas fa-exclamation-triangle"
      />,
    );
  };

  const removeOLA = index => {
    // Run checks before removal
    let hasParallel = false;
    let hasAssignment = false;
    if (parallels.filter((parallel) => olas[index].ID === parallel.mainOLAID).length > 0 || parallels.filter((parallel) => olas[index].ID === parallel.subOLAID).length > 0) {
      hasParallel = true;
    }

    if (assignments.filter((assignment) => olas[index].ID === assignment.olaID).length > 0) {
      hasAssignment = true;
    }

    if (hasParallel || hasAssignment) {
      if (hasParallel) {
        openMessageModal("Parallel bestaat nog", "Er bestaat nog een parallel voor dit OLA. Verwijder de parallel alvorens het OLA te verwijderen.")
      }
      if (hasAssignment) {
        openMessageModal("Toewijzingen bestaan nog", "Er bestaan nog toewijzingen voor dit OLA. Verwijder de toewijzingen alvorens het OLA te verwijderen.")
      }
    } else {
      const newOLAs = [...olas];
      newOLAs.splice(index, 1);
      setOLAs(newOLAs);
    }
  };

  const createFirstOLA = () => {
    if (name === "" || olas.length > 0) {
      return;
    }

    setOLAs([
      {
        name,
      },
    ]);
  };

  const checkOLAs = () => {
    let invalidOLAFound = false;
    olas.forEach(ola => {
      if (ola.name === "" || ola.name === null) {
        invalidOLAFound = true;
      }
    });
    setHasInvalidOLA(invalidOLAFound);
  }

  useEffect(() => {
    api.getProgrammes(snapshot).then(
      res => setProgrammes(res),
      error => toastService.send({ title: "An error occured", message: error }),
    );
  }, [snapshot]);

  useEffect(() => {
    checkOLAs();
    // eslint-disable-next-line
  }, [olas]); 

  const updateOLA = async (index, ola) => {
    const newOLAs = [...olas];
    newOLAs[index] = ola;
    const newWorstCaseVTE = await calculateOPOVTEWorstCase(newOLAs);
    setVTEWorstCase(newWorstCaseVTE);
    const newBestCaseVTE = await calculateOPOVTEBestCase(newOLAs);
    setVTEBestCase(newBestCaseVTE);
    setOLAs(newOLAs);
  };

  const onFormSubmit = async e => {
    e.preventDefault();
    setSaving(true);
    try {
      if (data.ID) {
        await api.updateOPO(data.ID, { name, zCode, programmeID, tags, olas, comments });
      } else {
        await api.createOPO({ name, zCode, programmeID, tags, olas, comments });
      }

      api.createSnapshot();
      toastService.send({ title: "Success", message: "OPO is opgeslagen" });
      hideModal();
    } catch (error) {
      toastService.send({ title: "An error occured", message: error.toString() });
    }
    setSaving(false);
  };
  return (
    <Modal
      show
      onHide={() => {
        hideModal();
      }}
      backdrop="static"
      keyboard={false}
      centered
      size="lg">
      {messageModal}
      <Modal.Header closeButton>
        <Modal.Title>OPO {data.ID ? "bewerken" : "aanmaken"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col xs={12}>
            <Form onSubmit={onFormSubmit}>
              <Form.Group className="mb-3">
                <Form.Label>Naam</Form.Label>
                <Form.Control type="text" required onChange={e => setName(e.target.value)} onBlur={createFirstOLA} value={name} />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Z-Code</Form.Label>
                <Form.Control type="text" required onChange={e => setZCode(e.target.value)} value={zCode} />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Tags</Form.Label>
                <ReactTags
                  tags={tags.map(tag => ({ id: tag.name, text: tag.name }))}
                  delimiters={[KeyCodes.comma, KeyCodes.enter]}
                  handleDelete={i => setTags(tags.filter((tag, index) => index !== i))}
                  handleAddition={tag => setTags([...tags, { name: tag.text }])}
                  handleDrag={(tag, currPos, newPos) => {
                    const newTags = tags.slice();
                    newTags.splice(currPos, 1);
                    newTags.splice(newPos, 0, tag);
                    setTags(newTags);
                  }}
                  inline
                />
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Opleiding</Form.Label>
                <Form.Select onChange={e => setProgramme(parseInt(e.target.value, 10))} value={programmeID}>
                  <option value={null}>Selecteer een opleiding</option>
                  {programmes.map(p => (
                    <option key={p.ID} value={p.ID}>
                      {p.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>

              <Form.Group className="mb-3">
                <Row>
                  <Col xs={6}>
                    <Form.Label>Onderdelen</Form.Label>
                  </Col>
                  <Col xs={6} className="justify-content-end d-flex">
                    <Button variant="success" onClick={() => setOLAs([{}, ...olas])}>
                      <span>
                        <i className="far fa-plus" /> Toevoegen{" "}
                      </span>
                    </Button>
                  </Col>
                </Row>
                {olas.map((ola, i) => (
                  <EditOLA data={ola} key={ola.ID} removeOLA={() => removeOLA(i)} programmeID={programmeID} saveData={d => updateOLA(i, d)} />
                ))}
              </Form.Group>

              <Form.Group className="mb-3">
                <Form.Label>Opmerkingen</Form.Label>
                <Form.Control as="textarea" onChange={e => setComments(e.target.value)} value={comments} />
              </Form.Group>

              <p className="font-weight-bold">Maximale begroting OPO: {worstCaseVTE} VTE</p>
              <p className="font-weight-bold">Minimale begroting OPO: {bestCaseVTE} VTE</p>

              <Button variant="primary" type="submit" disabled={snapshot || saving || olas.length < 1 || hasInvalidOLA}>
                {data.ID ? (
                  <span>
                    <i className="far fa-edit" /> Bewerk
                  </span>
                ) : (
                  <span>
                    <i className="far fa-plus" /> Aanmaken{" "}
                  </span>
                )}
              </Button>

              {olas.length < 1 && <Form.Group className="mb-3 text-danger">Een OPO heeft minstens 1 OLA nodig</Form.Group>}
              {hasInvalidOLA && <Form.Group className="mb-3 text-danger">Lege OLA&apos;s zijn niet toegelaten!</Form.Group>}
            </Form>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default EditModal;
