import { DefaultApi, DefaultApiFetchParamCreator } from "./api/src";
import { API_URL } from "../Config";
import { msalInstance } from '../index';


const localFetch = async (url, options) => {
  const accessTokenRequest = {
    scopes: ["api://1f4ec7ec-268e-40a7-94c0-70c6f9f28a36/Read.All"],
    account: msalInstance.getActiveAccount(),
  };
  
  let accessToken;
  return msalInstance
    .acquireTokenSilent(accessTokenRequest)
    .then((accessTokenResponse) => {
      // Acquire token silent success
      accessToken = accessTokenResponse.accessToken;
      //console.log(accessToken);
      //console.log(url, options);
    options.headers.Authorization = `Bearer ${accessToken}`;
    return fetch(url, options).then(res => {
      if (res.status === 401) {
        localStorage.removeItem("token");
      }
      //console.log("test")
      return res;
    });
    })
    ;

};
 

// this exporter is only used if you need to have raw fetch() access!
// otherwise you can use the default exporter

export const getApiURL = () => API_URL;
export const getFetchParamGenerator = () => {
  const fetchParamCreator = new DefaultApiFetchParamCreator({ basePath: API_URL });
  return { fetchParamCreator, fetch: localFetch };
};

export default () => {
  // inject our own fetch as the openapi generator doesn't support Bearer auth
  const api = new DefaultApi({ basePath: API_URL }, localFetch);

  return api;
};
